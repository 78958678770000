import "./App.css";
import { Routes, Route } from "react-router-dom";
import CreateCustomer from "./components/Customer/CreateCustomer";
import Main from "./components/Main";
import CustomerDetails from "./components/Customer/CustomerDetails";
import UserTable from "./components/User/UserTable";
import CreateUser from "./components/User/CreateUser";
import LoginPage from "./components/LoginPage";
import ModalComment from "./components/Comment/ModalComment";
import VerificationTable from "./components/VerificationTable";
import UserEdit from "./components/User/UserEdit";
import TestTable from "./components/TestTable";
import PaymentsTable from "./components/PaymentsTable";
import MainServices from "./components/ClientService/MainServices";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/customer/new" element={<CreateCustomer />} />
        <Route path="/customer/:id" element={<CustomerDetails />} />
        <Route path="/all/users" element={<UserTable />} />
        <Route path="/user/new" element={<CreateUser />} />
        <Route path="/all/users/user/:id" element={<UserEdit />} />
        <Route path="/comment/new/:customerid" element={<ModalComment />} />
        <Route path="/verification" element={<VerificationTable />} />
        <Route path="/payments" element={<PaymentsTable />} />
        <Route path="/services" element={<MainServices />} />
        <Route path="/test" element={<TestTable />} />
      </Routes>
    </div>
  );
}

export default App;
