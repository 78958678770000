import React from "react";
import { Card, CardContent, Typography, Grid, Button } from "@mui/material";

const StatusCounter = ({ statusCounterResult }) => {
  const [buttonText, setButtonText] = React.useState("Show Stats");

  const formattedRevenue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(statusCounterResult.revenue ? statusCounterResult.revenue : 0);

  return (
    <div>
      <Button
        onClick={() =>
          setButtonText(buttonText === "Show Stats" ? "Hide All" : "Show Stats")
        }
      >
        {buttonText}
      </Button>

      {buttonText === "Hide All" ? (
        <>
          <Card sx={{ minWidth: 50, maxHeight: 90, marginBottom: 1 }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 10, textAlign: "center" }}
                color="text.secondary"
                gutterBottom
              >
                TOTAL REVENUE
              </Typography>
              <Typography sx={{ textAlign: "center" }} color="text.secondary">
                {formattedRevenue}
              </Typography>
            </CardContent>
          </Card>

          <Grid
            display="grid"
            gridTemplateColumns="repeat(4, 1fr)"
            gap={1}
            sx={{ marginBottom: 1 }}
          >
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    PENDING APPROVAL
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult["Pending approval"]
                      ? statusCounterResult["Pending approval"]
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    PENDING INSTALL
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult["Pending install"]
                      ? statusCounterResult["Pending install"]
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    PENDING CONTRACT
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult["Pending contract"]
                      ? statusCounterResult["Pending contract"]
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    SIGNING
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult.Signing
                      ? statusCounterResult.Signing
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    VERIFICATION
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult["In verification"]
                      ? statusCounterResult["In verification"]
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    VERIFIED
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult.Verified
                      ? statusCounterResult.Verified
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    PAID
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult.Paid ? statusCounterResult.Paid : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    CANCELLED
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult.Cancelled
                      ? statusCounterResult.Cancelled
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid gridColumn="span 1">
              <Card sx={{ minWidth: 50, maxHeight: 90 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 10, textAlign: "center" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    DECLINED
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {statusCounterResult.Declined
                      ? statusCounterResult.Declined
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default StatusCounter;
