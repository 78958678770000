import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Grid, TextField, Container, Typography } from "@mui/material";

const FilterManagerView = ({ handleFilter, userOffice }) => {
  const [allUsersOffice, setAllUsersOffice] = useState([]);
  const [value, setValue] = useState("All");
  const [seller, setSeller] = useState("All");

  const [custData, setCustData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //api call for all reps
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/allUsers", {
        withCredentials: true,
      })
      .then((response) => {
        const users = response.data.filter(
          (user) => user.office === userOffice
        );

        setAllUsersOffice(users);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box sx={{ minWidth: 120 }}>
      <Typography variant="h5">Filter Information</Typography>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Status"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Pending approval">Pending Approval</MenuItem>
          <MenuItem value="Pending install">Pending Install</MenuItem>
          <MenuItem value="Pending contract">Pending contract</MenuItem>
          <MenuItem value="Signing">Signing</MenuItem>
          <MenuItem value="In verification">Verification</MenuItem>
          <MenuItem value="Verified">Verified</MenuItem>
          <MenuItem value="Paid">Paid</MenuItem>
          <MenuItem value="Cancelled">Cancelled</MenuItem>
          <MenuItem value="Declined">Declined</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ minWidth: 120, marginTop: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Seller</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Status"
            value={seller}
            onChange={(e) => setSeller(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {allUsersOffice.length > 0 &&
              allUsersOffice.map((user, i) => {
                return (
                  <MenuItem value={user._id} key={i}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>

      <Container sx={{ marginTop: 1 }}>
        <Box display="flex" justifyContent="center" mb={3}>
          <Typography variant="h5">Customer Information</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              label="First Name"
              name="firstName"
              value={custData.firstName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Last Name"
              name="lastName"
              value={custData.lastName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Address"
              name="address"
              value={custData.address}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={custData.phoneNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Container>

      <Button
        onClick={() => handleFilter(value, seller, custData)}
        variant="contained"
        sx={{ marginTop: 2, marginBottom: 2 }}
      >
        Apply filter / Show data
      </Button>
    </Box>
  );
};

export default FilterManagerView;
