import React from 'react'
import {useEffect, useState} from 'react'
import axios from 'axios'


const TestTable = (props) => {


  const servicesList = props.services



  

  




  return (
    <div>

      <h1>List from Test component</h1>
      
      {servicesList.map((serv, i)=>(
      <h1 key={i}>{serv.firstName}</h1>
  


    ))}</div>
  )
}

export default TestTable