const statusCounter = (customersArray) => { 
    let totalRevenue = 0
    const statusCount = customersArray.reduce((acc, customer) => {
        
        totalRevenue += customer.price
        acc['revenue'] = totalRevenue

        if (customer.status in acc) {
        acc[customer.status]++
        }
        else {
        acc[customer.status] = 1
        }

      
        return acc
    }, {})
  
   
    return statusCount




}

export default statusCounter;

