import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBarMui from "../NavBarMui";
import ServicesTable from "./ServicesTable";
import { Navigate, useNavigate } from "react-router-dom";
import TestTable from "../TestTable";

const MainServices = () => {
  const [services, setServices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/services", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setServices(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  //Function para agregar servicio nuevo
  const filterServiceList = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/services", {
        withCredentials: true,
      })
      .then((response) => {
        setServices(response.data);
      })
      .catch((err) => navigate("/login"));
  };

  return (
    <div>
      <NavBarMui />

      <ServicesTable
        services={services}
        onNewComment={filterServiceList}
        onNewService={filterServiceList}
      />
    </div>
  );
};

export default MainServices;
