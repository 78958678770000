import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import ModalService from "./ModalService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Textarea from "@mui/joy/Textarea";
import { TableSortLabel } from "@mui/material";

const ServicesTable = (props) => {
  const servicesList = props.services;

  const [orderBy, setOrderBy] = useState("dos");
  const [order, setOrder] = useState("asc");

  //variables
  const [userId, setUserId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [custFirstName, setCustFirstName] = useState("");
  const [custLastName, setCustLastName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [servId, setServId] = useState("");
  const [deleteId, setDeleteId] = useState("");

  let newComment = "";

  //modal variables
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/getUser", {
        withCredentials: true,
      })
      .then((response) => {
        setUserId(response.data._id);
        setUserFirstName(response.data.firstName);
        setUserLastName(response.data.lastName);
        setUserRole(response.data.role);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOpenServiceModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleNewServiceSubmit = (newService) => {
    // Handle submission of new service form data
    console.log("New service data:", newService);

    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/service/new`,
        { newService },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);

        props.onNewService();
      })
      .catch((err) => console.log(err));

    // Don't forget to close the modal after successful submission
    setIsModalOpen(false);
  };

  const handleChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleNewComment = (e) => {
    e.preventDefault();

    newComment = props.userRole + ": " + commentText;

    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/service/${servId}/comment`,
        { newComment },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        setOpen(false);
        props.onNewComment();
      })
      .catch((err) => console.log(err));
  };

  //open modal
  const handleOpen = (id, word) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/service/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        setCustFirstName(response.data.firstName);
        setCustLastName(response.data.lastName);
        setDescription(response.data.description);
        setComments(response.data.comments);
        setDeleteId(id);
        setServId(id);
      })
      .catch((err) => {
        console.log(err.response);
      });

    if (word === "comment") {
      console.log("comment abre");
      setOpenComment(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenComment(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (userRole === "admin" && props.services) {
    return (
      <div>
        <h1>Services</h1>

        <Button
          size="small"
          onClick={handleOpenServiceModal}
          variant="outlined"
        >
          Add a New Service
        </Button>

        <ModalService
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleNewServiceSubmit}
        />

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 840 }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      sx={{
                        padding: 3,
                        borderBottom: "1px solid gray",
                        minHeight: 81,
                      }}
                      active={orderBy === "dos"}
                      direction={order}
                      onClick={() => handleRequestSort("dos")}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Completed By</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell>Updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicesList.map((eachServ, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {moment(eachServ.createdAt).format("MMM DD, YY")}
                    </TableCell>
                    <TableCell>{eachServ.status}</TableCell>
                    <TableCell>
                      <Link to={`service/${eachServ._id}`}>
                        {eachServ.firstName} {eachServ.lastName}
                      </Link>
                    </TableCell>
                    <TableCell>{eachServ.phone}</TableCell>
                    <TableCell>{eachServ.address}</TableCell>
                    <TableCell>{eachServ.completedBy}</TableCell>
                    <TableCell>{eachServ.description}</TableCell>
                    <TableCell>
                      <Button
                        sx={{ marginBottom: 1 }}
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => handleOpen(eachServ._id)}
                      >
                        delete
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="info"
                        onClick={() => handleOpen(eachServ._id, "comment")}
                      >
                        Comments
                      </Button>
                    </TableCell>
                    <TableCell>
                      {eachServ.comments.length !== 0
                        ? eachServ.comments[eachServ.comments.length - 1].text
                        : "No comments"}
                    </TableCell>
                    <TableCell>
                      {moment(eachServ.updatedAt).format("dddd LT MM/DD/YY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
          </TableContainer>
        </Paper>

        <Box>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              Customer: {custFirstName} {custLastName} - Description:
              {description}{" "}
            </DialogTitle>
            <DialogContent>
              {comments.map((comment, i) => (
                <DialogContentText key={i}>
                  <span style={{ fontWeight: "bold" }}>
                    {moment(comment.timestamp).format("MMM DD, YY, hh:mm a")}
                  </span>
                  , {comment.text}{" "}
                </DialogContentText>
              ))}
            </DialogContent>

            <Textarea
              sx={{ minWidth: 400, margin: 2 }}
              minRows={2}
              onChange={handleChange}
              placeholder="Add comment here.."
            />

            <Box sx={{ maxWidth: 200 }}>
              <Button
                sx={{ margin: 2 }}
                size="small"
                variant="outlined"
                onClick={handleNewComment}
              >
                Add Comment
              </Button>
            </Box>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </div>
    );
  } else {
    return "YOU ARE NOT AN ADMIN"; // or any other component or message you want to display for non-admin users
  }
};

export default ServicesTable;
