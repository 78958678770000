import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

const ModalService = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    phone: "",
    completedBy: "",
    status: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(formData);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      phone: "",
      completedBy: "",
      status: "",
      description: "",
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>New Service Form</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out the form to add a new service:
        </DialogContentText>
        <TextField
          name="firstName"
          label="First Name"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <TextField
          name="lastName"
          label="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="address"
          label="Address"
          value={formData.address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="phone"
          label="Phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="completedBy"
          label="completedBy"
          value={formData.completedBy}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          name="status"
          label="Status"
          value={formData.status}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          <MenuItem value={"Pending Approval"}>Pending approval</MenuItem>
          <MenuItem value={"In progress"}>In progress</MenuItem>
          <MenuItem value={"Completed"}>Completed</MenuItem>
        </Select>

        <InputLabel id="demo-simple-select-label">Water source</InputLabel>
        <Select
          name="status"
          label="Status"
          value={formData.waterSource}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          <MenuItem value={"Well water"}>Well water</MenuItem>
          <MenuItem value={"City water"}>City water</MenuItem>
        </Select>

        <TextField
          name="description"
          label="Description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        {/* Other TextField components */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalService;
