import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useMediaQuery } from "@mui/material";
import FilterManagerView from "./FilterManagerView";
import statusCounter from "../../utils/statusCounter";
import StatusCounter from "../StatusCounter";

const ManagerView = ({ customers, userOffice, userRole, onNewComment }) => {
  const customersInOffice = customers.filter(
    (eachCust) => eachCust.user.office === userOffice
  );

  const [status, setStatus] = useState("All");
  const [sellerFilter, setSellerFilter] = useState("All");
  const [filterCustData, setFilterCustData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    phoneNumber: "",
  });

  //Filter by status
  if (status === "All") {
    var filteredData = customersInOffice;
  } else {
    var filteredData = customersInOffice.filter(
      (customer) => customer.status === status
    );
  }

  //Filter by seller
  if (sellerFilter !== "All") {
    filteredData = filteredData.filter(
      (customer) => customer.user._id === sellerFilter
    );
  }

  //Filter by customer data
  if (filterCustData.firstName !== "") {
    var filteredData = customersInOffice.filter((customer) =>
      customer.firstName
        .toLowerCase()
        .includes(filterCustData.firstName.toLowerCase())
    );
  }

  if (filterCustData.lastName !== "") {
    var filteredData = customersInOffice.filter((customer) =>
      customer.lastName
        .toLowerCase()
        .includes(filterCustData.lastName.toLowerCase())
    );
  }

  if (filterCustData.address !== "") {
    var filteredData = customersInOffice.filter((customer) =>
      customer.address
        .toLowerCase()
        .includes(filterCustData.address.toLowerCase())
    );
  }

  if (filterCustData.phoneNumber !== "") {
    var filteredData = customersInOffice.filter((customer) => {
      if (customer.phone && typeof customer.phone === "string") {
        return customer.phone.includes(filterCustData.phoneNumber);
      } else if (customer.phone && typeof customer.phone === "number") {
        return customer.phone.toString().includes(filterCustData.phoneNumber);
      } else {
        return false;
      }
    });
  }

  const isMobile = useMediaQuery("(max-width:600px)");

  //modal variables
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [custId, setCustId] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [office, setOffice] = useState("");

  const [orderBy, setOrderBy] = useState("dos");
  const [order, setOrder] = useState("asc");

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [filterStatus, setFilterStatus] = useState("All");

  let newComment = "";

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortByDate = (a, b) => {
    const dateA = new Date(a.dos);
    const dateB = new Date(b.dos);
    return order === "asc" ? dateA - dateB : dateB - dateA;
  };

  const sortedData = filteredData.sort(sortByDate);

  const handleNewComment = (e) => {
    e.preventDefault();

    newComment = userRole + ": " + commentText;

    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/${custId}/comment`,
        { newComment },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        setOpen(false);
        onNewComment();
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event) => {
    setCommentText(event.target.value);
  };

  //open modal
  const handleOpen = (id, word) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/customer/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setOffice(response.data.office);
        setComments(response.data.comments);
        setDeleteId(id);
        setCustId(id);
      })
      .catch((err) => {
        console.log(err.response);
      });

    if (word === "comment") {
      console.log("comment abre");
      setOpenComment(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenComment(false);
  };

  const handleFilter = (value, seller, custData) => {
    setFilterCustData(custData);
    setSellerFilter(seller);
    setStatus(value);
  };

  //Function to count statuses
  const statusCounterResult = statusCounter(filteredData);

  return (
    <div>
      <FilterManagerView handleFilter={handleFilter} userOffice={userOffice} />

      <StatusCounter statusCounterResult={statusCounterResult} />

      {filteredData.length > 0 && (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 840 }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      sx={{
                        padding: 3,
                        borderBottom: "1px solid gray",
                        minHeight: 81,
                      }}
                      active={orderBy === "dos"}
                      direction={order}
                      onClick={() => handleRequestSort("dos")}
                    >
                      DOS
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Office</TableCell>
                  <TableCell>Rep</TableCell>
                  <TableCell>Customer name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Coap</TableCell>
                  <TableCell>Coap Phone</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Approval</TableCell>
                  <TableCell>Bank</TableCell>
                  <TableCell>Payments / Interest</TableCell>
                  <TableCell>DOI</TableCell>
                  <TableCell>Installer</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell>Updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((eachCust, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {moment(eachCust.dos).format("MMM DD, YY")}
                        </TableCell>
                        <TableCell>{eachCust.status}</TableCell>
                        <TableCell>{eachCust.office}</TableCell>
                        <TableCell>
                          {eachCust.user ? (
                            <span
                              style={{
                                color: eachCust.user.lastName ? "black" : "red",
                              }}
                            >
                              {" "}
                              {eachCust.user.firstName
                                ? eachCust.user.firstName
                                : "no user assigned"}{" "}
                              {eachCust.user.lastName
                                ? eachCust.user.lastName
                                : ""}{" "}
                            </span>
                          ) : (
                            <span style={{ color: "red" }}>
                              no user assigned
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          {eachCust.firstName} {eachCust.lastName}
                        </TableCell>
                        <TableCell>{eachCust.phone}</TableCell>
                        <TableCell>${eachCust.price}</TableCell>
                        <TableCell>
                          {eachCust.coapFirstName} {eachCust.coapLastName}
                        </TableCell>
                        <TableCell>{eachCust.CoapPhone}</TableCell>
                        <TableCell>{eachCust.address}</TableCell>
                        <TableCell>{eachCust.approval}</TableCell>
                        <TableCell>{eachCust.bank}</TableCell>
                        <TableCell>{eachCust.paymentPlan}</TableCell>
                        <TableCell>
                          {eachCust.doi
                            ? moment(eachCust.doi).format("MMM DD, YY")
                            : "Pending"}
                        </TableCell>
                        <TableCell>{eachCust.installer}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            variant="contained"
                            color="info"
                            onClick={() => handleOpen(eachCust._id, "comment")}
                          >
                            View Comments
                          </Button>
                        </TableCell>
                        <TableCell>
                          {eachCust.comments.length !== 0
                            ? eachCust.comments[eachCust.comments.length - 1]
                                .text
                            : "No comments"}
                        </TableCell>
                        <TableCell>
                          {moment(eachCust.updatedAt).format(
                            "dddd LT MM/DD/YY"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      )}

      {/* Modal for comments */}
      <Box>
        <Dialog open={openComment} onClose={handleClose}>
          <DialogTitle>
            Customer: {firstName} {lastName} - Office:{office}{" "}
          </DialogTitle>
          <DialogContent>
            {comments.map((comment, i) => (
              <DialogContentText key={i}>
                <span style={{ fontWeight: "bold" }}>
                  {moment(comment.timestamp).format("MMM DD, YY, hh:mm a")}
                </span>
                , {comment.text}{" "}
              </DialogContentText>
            ))}
          </DialogContent>

          {/* <Textarea sx={{minWidth: 400, margin: 2}} minRows={2} onChange={handleChange} placeholder="Add comment here.."/>
        
        <Box sx={{maxWidth: 200}}>
        <Button sx={{ margin: 2}} size='small' variant="outlined" onClick={handleNewComment}>
        Add Comment
        </Button>
        </Box> */}

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default ManagerView;
